.link-container{
    width: max-content;
    margin: 20px;
    display: flex;
    align-items:last baseline;
    justify-content: center;
    @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
        flex-direction: column;
    }

    .link-wrapper{
        text-decoration: none;
        padding: 10px 20px;
        padding-bottom: 0;

        .link{
            color: white;
            text-decoration: none;
            font-size:20px;
            padding: 0 25px;
            padding-bottom: 10px;
            align-items: baseline;
            @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
                font-size: 30px;
            }
        }
    
        .link-hover{
            color: rgb(255, 50, 10);
            transform: translateY(-10px);
            border-bottom: 3px solid white;
            transition: all 0.3s ease-in-out
        }

        .active-link{
            color: rgb(255, 50, 10);
            cursor: default;
        }
    }
}