.home-wrapper{
    height: 100vh;
    width: 100vw;
    .home-container {
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        .link {
          font-family: 'prompt', serif;
          position: relative;
          display: inline-block;
          font-size: 10vw;
          padding: 0;
          line-height: 1em;
          margin: 0;
          padding-bottom: 0;
      
          &:hover {
            cursor: default;
      
            .link--top {
              color: rgb(12, 235, 179);
              transform: translateY(-0.5em) rotateZ(-3deg);
              transition: transform 0.2s cubic-bezier(0.12, 0.8, 0.57, 1) 0.42s;
              
              &:after {
                top: 61%;
                transform-origin: left top;
                transform: rotateZ(-1.7deg) scaleX(100%);
              }
            }

            .link--bottom {
              color: rgb(12, 235, 179);
              background-position: 100% bottom;
              transition: background-position 0.2s ease;
      
              &:after {
                top: 65%;
                transform-origin: left top;
                transform: rotateZ(-1.7deg) scaleX(100%);
              }
            }
          }
        }
      
        .link--top {
          position: absolute;
          color: rgb(255, 50, 10);
          top: 0;
          display: inline-block;
          clip-path: polygon(0% 66%, 0% 0%, 100% 0%, 100% 40%);
          transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1) 0.25s, color 0.5s ease 0.22s;
      
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 4%;
            background: rgb(12, 235, 179);
            transform: rotateZ(-2.3deg) scaleX(0%);
            transform-origin: right top;
            transition: transform 0.2s ease 0.22s;
          }
        }
      
        .link--bottom {
          display: inline-block;
          clip-path: polygon(0% 65%, 100% 40%, 100% 110%, 0% 110%);
          text-decoration: underline;
          color: rgb(255, 50, 10);
          transition: color 0.5s ease 0.22s, background-position 0.2s ease 0.22s;
          text-decoration: none;
          background-size: 200% 8%;
          background-position: left bottom;
          background-repeat: no-repeat;

          &:after {
            content: "";
            position: absolute;
            top: 30%;
            left: 0;
            width: 100%;
            height: 4%;
            background: rgb(12, 235, 179);
            transform: rotateZ(-2deg) scaleX(0%);
            transform-origin: right top;
            transition: transform 0.2s ease 0.22s;
          }
        }
      }
      
      

    #main-canvas{
        z-index: -1;
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-color: #1E1F26;
    }

    table{
        text-align: center;

        td{
            width: 50%;
        }
    }
}